import { type Enum } from '../../utils/enums'

export const PaymentStatuses = {
  DRAFT: 0,
  CONFIRMED: 1, // FKA "Pending"
  APPROVED: 2,
  DENIED: 3,
  PAID: 5,
  PROCESSING: 7,
  FAILED: 8,
  SCHEDULED: 9,
} as const
export type PaymentStatus = Enum<typeof PaymentStatuses>
