import * as React from 'react'

import {
  UIProvider as UIProviderComponent,
  type IUIProviderValue,
  defaultUIContextValues,
} from '@procurify/ui'
import { FILE_STATES, type UploadData } from '@rpldy/shared'

import { ATTACHMENTS_URL } from '@webapp/api/AttachmentsAPI'
import { useFormatMedia } from '@webapp/core/hooks/useFormatMedia'
import { IS_PROD } from '@webapp/infrastructure/envs'
import { ApiClient } from '@webapp/services/api'
import { getCSRFToken } from '@webapp/utils/csrftoken'

export const UIProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const formatMedia = useFormatMedia()

  const uiProviderValue: IUIProviderValue = {
    ...defaultUIContextValues,
    Uploader: {
      headers: {
        'X-CSRFTOKEN': getCSRFToken(),
      },
    },
    Upload: {
      endpoint: ATTACHMENTS_URL,
      getFileUrl: (res) => formatMedia(res?.data?.file),
      customSender: (item, url, options, onProgress) => {
        const formData = new FormData()

        formData.append('file', item[0].file as File)
        formData.append('name', item[0].file.name)
        formData.append('type', item[0].file.type)

        if (options.params) {
          for (const [key, value] of Object.entries<string>(
            options.params as Record<string, string>
          )) {
            formData.set(key, value.toString())
          }
        }

        const [xhr, abortFn] = ApiClient.cancellable.post(url, formData, {
          onUploadProgress: (progressEvent) => {
            onProgress(progressEvent, null)
          },
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        const uploadXhr = xhr
          .then((res) => {
            return {
              status: 200,
              state: FILE_STATES.FINISHED,
              response: res,
            } as UploadData
          })
          .catch((err) => {
            if (!IS_PROD) {
              console.log(err)
            }

            return {
              error: true,
              status: 500,
              state: FILE_STATES.ERROR,
              response: err,
            } as UploadData
          })

        return {
          abort: () => {
            abortFn()
            return true
          },
          request: uploadXhr,
          senderType: 'xhr',
        }
      },
    },
  }

  return (
    <UIProviderComponent value={uiProviderValue}>
      {children}
    </UIProviderComponent>
  )
}
