import { type Enum } from '@webapp/utils'

export const LinkedBankAccountStatuses = {
  PENDING: 0,
  VERIFIED: 1,
  REQUIRE_ACTION: 2,
  SUSPENDED: 3,
  VERIFICATION_FAILED: 4,
  REMOVED: 5,
} as const

export type LinkedBankAccountStatus = Enum<typeof LinkedBankAccountStatuses>
