import { defineMessages } from 'react-intl'

const scope = `procurify.hooks.useFormatPaymentMethodType`

export default defineMessages({
  otherLabel: {
    id: `${scope}.otherLabel`,
    defaultMessage: 'Other',
  },
  achLabel: {
    id: `${scope}.achLabel`,
    defaultMessage: 'ACH',
  },
  eftLabel: {
    id: `${scope}.eftLabel`,
    defaultMessage: 'EFT',
  },
  wireLabel: {
    id: `${scope}.wireLabel`,
    defaultMessage: 'Wire',
  },
  checkLabel: {
    id: `${scope}.checkLabel`,
    defaultMessage: 'Check',
  },
  externalOtherLabel: {
    id: `${scope}.externalOtherLabel`,
    defaultMessage: 'External - Other',
  },
  externalCheckLabel: {
    id: `${scope}.externalCheckLabel`,
    defaultMessage: 'External check',
  },
  externalAchLabel: {
    id: `${scope}.externalAchLabel`,
    defaultMessage: 'External ACH',
  },
  externalWireLabel: {
    id: `${scope}.externalWireLabel`,
    defaultMessage: 'External wire',
  },
  procurifyAchLabel: {
    id: `${scope}.procurifyAchLabel`,
    defaultMessage: 'Procurify ACH',
  },
  procurifyEftLabel: {
    id: `${scope}.procurifyEftLabel`,
    defaultMessage: 'Procurify EFT',
  },
  procurifyWireLabel: {
    id: `${scope}.procurifyWireLabel`,
    defaultMessage: 'Procurify Wire',
  },
  procurifyCheckLabel: {
    id: `${scope}.procurifyCheckLabel`,
    defaultMessage: 'Procurify Check',
  },
})
