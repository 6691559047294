import { type Enum } from '@webapp/utils'

export const PaymentSourceTypes = {
  EXTERNAL_OTHER: 0,
  EXTERNAL_CHECK: 1,
  EXTERNAL_ACH: 2,
  FINANCIAL_ACCOUNT: 3,
  DIRECT_DEBIT: 4,
} as const

export type PaymentSourceType = Enum<typeof PaymentSourceTypes>
