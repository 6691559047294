import { Permissions } from '@webapp/core/entities/internal/enums/PermissionEnum'
import { useCheckPermission } from '../useCheckPermission'

export const useFinancialAccountPermissions = () => {
  const canManageFinancialAccount = useCheckPermission(
    Permissions.SETTINGS_MANAGE_FINANCIAL_ACCOUNT
  )

  return {
    canManageFinancialAccount,
  }
}
