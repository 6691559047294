import {
  type FeatureFlagKey,
  type FeatureFlags,
} from '@webapp/core/services/FeatureFlag'
import {
  UPDATE_FEATURE_FLAG,
  UPDATE_FEATURE_FLAGS,
  UPDATE_FEATURE_SWITCH,
  UPDATE_GLOBAL_CONTEXT,
  UPDATE_IS_PAY_SETUP,
  UPDATE_ORDER_CUSTOMFIELD,
  UPDATE_ORDER_ITEM_CUSTOMFIELD,
  UPDATE_USER,
} from './constants'
import {
  type UpdateOrderItemCustomFieldAction,
  type IGlobalState,
  type UpdateFeatureFlagAction,
  type UpdateFeatureFlagsAction,
  type UpdateFeatureSwitchAction,
  type UpdateGlobalAction,
  type UpdateIsPaySetupAction,
  type UpdateOrderCustomFieldAction,
  type UpdateUserAction,
} from './types'
import { type CustomField, type User } from '../../models'

export const updateGlobal = (
  global: Partial<IGlobalState>
): UpdateGlobalAction => ({ type: UPDATE_GLOBAL_CONTEXT, global })

export const updateOrderCustomfield = (
  customfields: CustomField[]
): UpdateOrderCustomFieldAction => ({
  type: UPDATE_ORDER_CUSTOMFIELD,
  customfields,
})

export const updateOrderItemCustomfield = (
  customfields: CustomField[]
): UpdateOrderItemCustomFieldAction => ({
  type: UPDATE_ORDER_ITEM_CUSTOMFIELD,
  customfields,
})

export const updateUser = (user: Partial<User>): UpdateUserAction => {
  return {
    type: UPDATE_USER,
    user,
  }
}

export const updateFlag = (
  flag: FeatureFlagKey,
  value
): UpdateFeatureFlagAction => {
  return { type: UPDATE_FEATURE_FLAG, flag, value }
}

export const updateFlags = (
  flags: Partial<FeatureFlags>
): UpdateFeatureFlagsAction => {
  return { type: UPDATE_FEATURE_FLAGS, flags }
}

export const updateFeatureSwitch = (flag, value): UpdateFeatureSwitchAction => {
  return { type: UPDATE_FEATURE_SWITCH, flag, value }
}

export const updateIsPaySetUp = (value: boolean): UpdateIsPaySetupAction => {
  return { type: UPDATE_IS_PAY_SETUP, value }
}
