/**
 * Create the store with dynamic reducers
 */

import * as Sentry from '@sentry/react'
import { routerMiddleware } from 'connected-react-router/immutable'
import {
  type Action,
  applyMiddleware,
  compose,
  createStore,
  type Store,
} from 'redux'
import createSagaMiddleware from 'redux-saga'

import { IS_PROD } from '@webapp/infrastructure/envs'
import createReducer, { type RootState } from './reducers'

const sagaMiddleware = createSagaMiddleware()

// // Hot Module Replacement API
declare let module: { hot: any }

export default function configureStore(initialState = {}, history) {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [sagaMiddleware, routerMiddleware(history)]

  const sentryReduxEnhancer = Sentry.createReduxEnhancer()
  const enhancers = [applyMiddleware(...middlewares), sentryReduxEnhancer]

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle, indent */
  const composeEnhancers =
    !IS_PROD &&
    typeof window === 'object' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose
  /* eslint-enable */

  const store = createStore(
    createReducer(),
    initialState,
    composeEnhancers(...enhancers)
  ) as Store<RootState, Action<any>> & {
    runSaga: any
    injectedReducers: object
    injectedSagas: object
    injectReducer: (...args) => void
  }

  // Extensions
  store.runSaga = sagaMiddleware.run
  store.injectedReducers = {} // Reducer registry
  store.injectedSagas = {} // Saga registry

  store.injectReducer = (key, asyncReducer) => {
    if (store.injectedReducers[key]) return
    store.injectedReducers[key] = asyncReducer
    store.replaceReducer(createReducer(store.injectedReducers))
  }

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers))
    })
  }

  return store
}
