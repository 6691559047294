import { type Enum } from '@webapp/utils'

export const SettingsBillFormControlTypes = {
  BILL_FIELD_INVOICE_NUMBER_REQUIRED: 'bill_field_invoice_number_required',
  VERIFIED_THREEWAY_WAY_MATCHING: 'verified_three_way_matching',
  USE_UNBILLED_PO_ITEM_DESCRIPTION: 'use_unbilled_po_item_description',
}

export type SettingsBillFormControlType = Enum<
  typeof SettingsBillFormControlTypes
>
