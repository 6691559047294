import { type Enum } from '../../utils/enums'

export const ContentTypeAppLabels = {
  AP: 'ap',
  APPROVAL: 'approval',
  CONTRACT: 'contract',
  CUSTOM_FIELD: 'custom_field',
  EXPORTER: 'exporter',
  PAYMENT: 'payment',
  PROCURE: 'procure',
  PROCURIFY: 'procurify',
  RECEIPT: 'receipt',
  RECONCILE: 'reconcile',
  STRIPE: 'stripe',
  PAY: 'pay',
  COMMON: 'common',
  VENDOR: 'vendor',
} as const

export type ContentTypeAppLabel = Enum<typeof ContentTypeAppLabels>

export const ContentTypeModels = {
  BILL: 'bill',
  BILLGROUP: 'billgroup',
  BILLPAYMENT: 'billpayment',
  ITEM: 'item',
  APPROVALDELEGATION: 'approvaldelegation',
  CUSTOMFIELD: 'customfield',
  EXPORTTASK: 'exporttask',
  COMPANYPAYMENTMETHOD: 'companypaymentmethod',
  CREDITCARD: 'creditcard',
  PAYMENT: 'payment',
  TRANSACTION: 'transaction',
  VENDORPAYMENTMETHOD: 'vendorpaymentmethod',
  PURCHASEAGREEMENT: 'purchaseagreement',
  PURCHASEAGREEMENTITEM: 'purchaseagreementitem',
  ACCOUNTCODE: 'accountcode',
  COMPANYACCOUNT: 'companyaccount',
  DEPARTMENT: 'department',
  EXPENSE: 'expense',
  EXPENSEREPORT: 'expensereport',
  ORDER: 'order',
  ORDERITEM: 'orderitem',
  PO: 'po',
  TAX: 'tax',
  TRAVEL: 'travel',
  VENDOR: 'vendor',
  VENDORREQUEST: 'vendorrequest',
  CREDITCARDITEM: 'creditcarditem',
  CARDTOPUPREQUEST: 'cardtopuprequest',
  REQUEST: 'request',
  CATALOGITEMS: 'catalogitems',
  PAYMENTTERM: 'paymentterm',
  CONTRACT: 'contract',
  LINKEDBANKACCOUNT: 'linkedbankaccount',
} as const

export type ContentTypeModel = Enum<typeof ContentTypeModels>
