import { type Payment } from '@webapp/models'
import { PaymentSourceTypes } from '@webapp/models/enums/PaymentSourceType'
import messages from './messages'
import { useProcurifyIntl } from '../useProcurifyIntl'

export function useFormatPaymentSourceAccountLabel(): (
  payment: Payment
) => string {
  const intl = useProcurifyIntl()

  return (payment: Payment): string => {
    switch (payment.payment_source_type) {
      case PaymentSourceTypes.DIRECT_DEBIT:
        return `****${payment.direct_debit_bank_account_mask}`
      case PaymentSourceTypes.EXTERNAL_OTHER:
      case PaymentSourceTypes.EXTERNAL_CHECK:
      case PaymentSourceTypes.EXTERNAL_ACH:
        return intl.formatMessage(messages.externalAccountLabel)
      case PaymentSourceTypes.FINANCIAL_ACCOUNT:
      default:
        return null
    }
  }
}
