import { type History, type Location } from 'history'
import { isUndefined, pick } from 'lodash'
import QueryString from 'query-string'
import { useHistory, useLocation } from 'react-router'

import { type DataManagerParams } from '@webapp/components/DataManager'

/**
 * @deprecated This function should not be used directly. use `useLocationHelper`.
 *
 * Once all class based components has been migrated, this function will
 * not be exported directly.
 */
export const search = (
  location: Location,
  history: History,
  param?: string | object,
  value?: string
): { [x: string]: string | string[] } | string | string[] => {
  const currentParams = {
    ...QueryString.parse(location.search),
  }

  let finalParams = {}

  if (typeof param === 'string' && !isUndefined(value)) {
    finalParams = {
      ...currentParams,
      [param]: value === null ? undefined : value,
    }
  } else if (typeof param === 'string' && isUndefined(value)) {
    return currentParams[param]
  } else if (typeof param === 'object') {
    finalParams = param
  } else {
    return currentParams
  }

  history.push(`${location.pathname}?${QueryString.stringify(finalParams)}`)
}

/**
 * @deprecated This function should not be used directly. use `useLocationHelper`.
 *
 * Once all class based components has been migrated, this function will
 * not be exported directly.
 */
export const getPaginationParams = (location: Location, history: History) => {
  const params = pick(search(location, history), [
    'search',
    'page_size',
    'page',
    'order_by',
  ]) as any

  const { page, page_size } = params

  return {
    ...params,
    ...(page_size ? { page_size: parseInt(page_size, 10) } : {}),
    ...(page ? { page: parseInt(page, 10) } : {}),
  } as DataManagerParams
}

/**
 * @deprecated This function should not be used directly. use `useLocationHelper`.
 *
 * Once all class based components has been migrated, this function will
 * not be exported directly.
 */
export const modifySearch = (
  location: Location,
  history: History,
  param: object
) => {
  const finalParams = {
    ...QueryString.parse(location.search),
    ...param,
  }

  history.replace(`${location.pathname}?${QueryString.stringify(finalParams)}`)
}

export const useLocationHelper = () => {
  const location = useLocation()
  const history = useHistory()

  return {
    search: (param?: string | object, value?: string) => {
      return search(location, history, param, value)
    },
    getPaginationParams: () => {
      return getPaginationParams(location, history)
    },
    modifySearch: (param: object) => {
      return modifySearch(location, history, param)
    },
    getQueryString: (params: Record<string, string | number> = {}) => {
      const finalParams = {
        ...QueryString.parse(location.search),
        ...params,
      }

      return `?${QueryString.stringify(finalParams)}`
    },
  }
}
