import * as React from 'react'

export interface ModalContext {
  component: React.ComponentType<any> | null
  componentProps: any
  hideModal: () => void
  dismissModal: () => void
  showModal: <Props = any>(
    component: React.ComponentType | ((props: Props) => JSX.Element),
    componentProps: Props
  ) => Promise<any>
}

export const ModalContext = React.createContext<ModalContext>({
  component: null,
  componentProps: {},
  hideModal: () => {
    console.warn('hideModal method not implemented')
  },
  dismissModal: () => {
    console.warn('dismissModal method not implemented')
  },
  showModal: () => {
    console.warn('showModal method not implemented')
    return Promise.resolve()
  },
})

export const ModalContextProvider = ModalContext.Provider
export const ModalContextConsumer = ModalContext.Consumer
