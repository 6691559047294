import { defineMessages } from 'react-intl'

const scope = `procurify.hooks.useFormatPaymentSourceAccountLabel`

export default defineMessages({
  externalAccountLabel: {
    id: `${scope}.externalAccountLabel`,
    defaultMessage: 'External account',
  },
})
