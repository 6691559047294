import * as React from 'react'

import { useSelector } from 'react-redux'

import {
  type FeatureFlagKey,
  FeatureFlagService,
} from '@webapp/core/services/FeatureFlag'
import { makeSelectFeatureFlag } from '@webapp/stores/global'

export const useFeatureFlag = (featureFlagKey: FeatureFlagKey) => {
  try {
    const featureFlagService = FeatureFlagService.getInstance()
    return featureFlagService.getFlag(featureFlagKey)
  } catch (e) {
    return !!useSelector(makeSelectFeatureFlag(featureFlagKey))
  }
}
