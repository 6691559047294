import Decimal from 'decimal.js'

import { type BudgetGroupBudget } from '@webapp/models'
import type {
  IFixedBudget,
  FixedBudgetSubtotalList,
  IFixedBudgetFormat,
  IFlexibleBudget,
  IFlexibleBudgetFormat,
  BudgetSubtotalList,
  BudgetFormat,
} from './types'

type BudgetFormatter = (budget: BudgetGroupBudget) => BudgetFormat
type FlexibleBudgetSubtotalsMap = Record<BudgetSubtotalList, Decimal>
type FixedBudgetSubtotalsMap = Record<FixedBudgetSubtotalList, Decimal>

/**
 * Returns a function to calculate the budget subtotals for a budget group that may or may not have a budget amount.
 * @returns BudgetFormatter function
 */
export const useCalculateBudgetSubtotals = (): BudgetFormatter => {
  const formatBudgetSubtotals = (budget: BudgetGroupBudget): BudgetFormat => {
    if (!budget) return

    if (budget.amount == null) {
      // get values for flexible budget without percentages
      const subtotals = calculateFlexibleBudgetValues(budget as IFlexibleBudget)

      return {
        budgetType: 'flexible',
        id: budget.id,
        isOverBudget: false,
        ...subtotals,
      }
    } else {
      // get values for fixed budget with percentages
      const subtotalValues = calculateFixedBudgetValues(budget as IFixedBudget)
      const subtotals = calculateFixedBudgetPercentages(subtotalValues)

      return {
        budgetType: 'fixed',
        id: budget.id,
        isOverBudget: subtotalValues.remaining.isNegative(),
        ...subtotals,
      }
    }
  }

  return formatBudgetSubtotals
}

/**
 * Calculates the core/shared spend numbers for categories that do and do not have a budget amount.
 * @param budget: BudgetGroupBudget
 * @returns FlexibleBudgetSubtotalsMap
 */
const calculateSharedBudgetValues = (
  budget: BudgetGroupBudget
): FlexibleBudgetSubtotalsMap => {
  const approved = new Decimal(budget.approved ?? 0)
  const purchased = new Decimal(budget.purchased ?? 0)
  const billed = new Decimal(budget.invoiced ?? 0)
  const received = new Decimal(budget.received ?? 0)
  const committed = approved.plus(purchased).plus(billed).plus(received)

  const spendRequest = new Decimal(budget.request_pending_amount ?? 0)
  // Pending includes the requested spend amount. Requested spend is removed for pages that have a `spendRequest` as it is displayed on another line.
  const pending = new Decimal(budget.pending ?? 0).minus(spendRequest)
  const spendTotal = committed.plus(pending).plus(spendRequest)

  return {
    approved,
    billed,
    committed,
    pending,
    purchased,
    received,
    spendRequest: spendRequest.isZero() ? null : spendRequest,
    spendTotal,
  }
}

/**
 * Formats the data structure for budgets that do not have a budget amount that spend can be measured against.
 * @param budget: IFlexibleBudget
 * @returns IFlexibleBudgetFormat
 */
const calculateFlexibleBudgetValues = (
  budget: IFlexibleBudget
): IFlexibleBudgetFormat => {
  const subtotals = calculateSharedBudgetValues(budget)
  const subtotalsList = {} as IFlexibleBudgetFormat

  Object.keys(subtotals).forEach((key) => {
    const decimal: Decimal = subtotals[key]
    if (!decimal) return
    subtotalsList[key] = {
      amount: decimal.toNumber(),
    }
  })

  return subtotalsList
}

/**
 * Calculates additional spend values and adds them to the core spend numbers for a budget group that has a budget that spend can be measured against.
 * @param budget: IFixedBudget
 * @returns FixedBudgetSubtotalsMap
 */
const calculateFixedBudgetValues = (
  budget: IFixedBudget
): FixedBudgetSubtotalsMap => {
  const {
    approved,
    billed,
    committed,
    pending,
    purchased,
    received,
    spendRequest,
    spendTotal,
  } = calculateSharedBudgetValues(budget)
  const total = new Decimal(budget.amount ?? 0)
  const remaining = new Decimal(budget.variance ?? 0)
  const remainingMinusPending = remaining.minus(pending)

  return {
    billed,
    approved,
    purchased,
    pending,
    remaining,
    received,
    committed,
    total,
    remainingMinusPending,
    spendRequest,
    spendTotal,
  }
}

/**
 * Calculates the percentage values for budgets that have an amount that spend can be measured against.
 * @param subtotals: FixedBudgetSubtotalsMap
 * @returns IFixedBudgetFormat
 */
const calculateFixedBudgetPercentages = (
  subtotals: FixedBudgetSubtotalsMap
): IFixedBudgetFormat => {
  const subtotalsList = {} as IFixedBudgetFormat

  Object.keys(subtotals).forEach((key) => {
    const decimal: Decimal = subtotals[key]
    if (!decimal) return
    const amount = decimal.toDP(2).toNumber()
    const percentage = decimal.dividedBy(subtotals.total)

    Decimal.set({ rounding: Decimal.ROUND_UP })
    if (percentage.isNegative()) {
      Decimal.set({ rounding: Decimal.ROUND_DOWN })
    }

    const percentageRatio = percentage.isNegative()
      ? 0
      : percentage?.toDP(4).toNumber()
    const percentageNumber = percentage?.times(100).toDP(4).toNumber() ?? 0

    let overagePercentage = 0
    if (percentage.isNegative()) {
      overagePercentage = percentage.absoluteValue().toDP(4).toNumber()
    }

    subtotalsList[key] = {
      amount,
      percentage: {
        amount: percentageRatio,
        number: percentageNumber,
        overage: overagePercentage,
      },
    }
  })

  return subtotalsList
}
