import { type Enum } from '@webapp/utils'

export const FinancialAccountTransactionTypes = {
  PAYOUT: 'PAYOUT',
  FEE: 'FEE',
  DEPOSIT: 'DEPOSIT',
  PAYOUT_REFUND: 'PAYOUT_REFUND',
  DEPOSIT_REVERSAL: 'DEPOSIT_REVERSAL',
} as const

export type FinancialAccountTransactionType = Enum<
  typeof FinancialAccountTransactionTypes
>
